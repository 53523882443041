import React from 'react'
import Layout from '../components/Layout';

const Politique = () => {
  return (
    <Layout>
    <div style={{width: '80vh', margin: '10vh auto'}}>
        <h3>Collecte des renseignements personnels</h3>
        <p>Nous collectons les renseignements suivants</p>
        <ul style={{marginLeft: '5vw'}}>
            <li>
                Nom
            </li>
            <li>
                E-mail
            </li>
        </ul>
        Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l'interactivité établie entre vous et notre site Web.
        <br></br><br></br>
        <h3>Formulaires et interactivité</h3>
        <p>Vos renseignements sont collectés par le bias de formulaire, à savoir:</p>
        <ul style={{marginLeft: '5vw'}}>
            <li>
                Formulaire de commande
            </li>
            <li>
                Formulaire de contact
            </li>
        </ul>
        <p>Ces renseignements passent par l'outil netlify et sont transférés par mail à l'adresse mail: vins@caveau-saillon.ch</p>
        <p>Ces renseignements personnels ne sont utilisés qu'afin de vous recontacter suite à votre message ou votre commande.</p>
        <br></br><br></br>
        

        <h3>Cookies</h3>
        <p> Seul le cookie concernant votre consentement est demandé directement par le site. 
            En cas d'acceptation, vous consentez aux cookies de services tiers provenant de google.com et youtube.com.
            Refuser les cookies ne compromettra pas l'utilisation du site.
        </p>
        <br></br><br></br>
        <h3>Demande de suppression</h3>
        <p> Vous pouvez à tout moment adresser une demande de suppression des renseignements personnels vous concernant au mail suivant: vins@caveau-saillon.ch</p>
    </div>
    </Layout>
  )
}

export default Politique;